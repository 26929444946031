import { useEffect, useRef, useState } from "react";
import { useSpring, animated } from 'react-spring';
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { monokaiSublime, railscasts } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { motion } from "framer-motion";
import belva_logo from "../assets/belva_logo.png";
import bwb from "../assets/bv_blue.png";
import belvaTOS from "../assets/BELVATOS.pdf"

import './xss.css';  // Custom CSS
import useTypingEffect from "./typing";
import axios from "axios";
import { AiOutlineTwitter } from "react-icons/ai"
import respell_logo from "../assets/white.png"
import { FaTelegramPlane } from "react-icons/fa"
import { FiMessageCircle, FiPhone, FiPhoneOff } from "react-icons/fi"
import { GiPhone } from "react-icons/gi"
import code from "../assets/carbon.png"
import { RxLapTimer } from "react-icons/rx"
import { MdVoicemail } from "react-icons/md"


const codeSnippet = ` {
	"phone_number": "7349303030", 
	"objective": "Order me a pizza with pepperoni to deliver to the Marina.", 

	"params": {
	  "calling_for":"Dominoes",
	  "toppings":"Pepperoni"
	}
	"v":0
}`;

const sectionVariants = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
};


const FAQItem = ({ question, answer }) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className="mb-4">
			<button
				className="w-full flex justify-between items-center py-4 px-6  text-gray-900 md:text-xl text-sm font-bold rounded-none focus:outline-none"
				onClick={toggleAccordion}
			>
				<span>{question}</span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className={`h-6 w-6 transform ${isOpen ? "rotate-180" : "rotate-0"}`}
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M19 9l-7 7-7-7"
					/>
				</svg>
			</button>
			{isOpen && (
				<div className="mt-2 p-4 bg-gray-200 text-gray-800 rounded-none glass">
					<p className="text-lg">{answer}</p>
				</div>
			)}
		</div>
	);
};

const LandingPage = () => {
	const phoneNumber = useRef(null)
	const [callInProgress, setCallInProgress] = useState(false)
	const [callLimitReached, setCallLimitReached] = useState(false);

	useEffect(() => {
		const iccpv = localStorage.getItem('iccpv');
		if (iccpv && Number(iccpv) >= 3) {
			setCallLimitReached(true);
		}
	}, []);
	const useCases = [
		{
			title: "Restaurant Reservations",
			details: "Harness the power of Belva to secure reservations at your favorite dining spots. With automated calling, your reservation is just a click away."
		},
		{
			title: "Healthcare Appointments",
			details: "Leverage Belva's capabilities to schedule your next doctor's appointment. Health made easy and accessible."
		},
		{
			title: "E-commerce Orders",
			details: "Streamline your e-commerce business by using Belva for automated order confirmations. Enhance your business efficiency and customer service."
		},
		// ... Add more use cases
	];

	const [selectedUseCase, setSelectedUseCase] = useState(null);
	const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1];
	const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
	const fadeIn = useSpring({
		from: { opacity: 0, marginTop: -500 },
		to: { opacity: 1, marginTop: 0 },
		config: { duration: 1000 }
	});
	const typedBelva = useTypingEffect("Belva", 100);  // 100ms delay between each character
	const issueWelcomeCall = async () => {
		if (callLimitReached) {
			// alert('You have reached the call limit.');
			return;
		}
		setCallInProgress(true)
		try {
			const response = await axios.post('/api/signup/welcomeCall', { phoneNumber: phoneNumber?.current?.value });
			const iccpv = localStorage.getItem('iccpv');
			const nextValue = iccpv ? Number(iccpv) + 1 : 1;
			localStorage.setItem('iccpv', nextValue.toString());

			if (nextValue >= 3) {
				setCallLimitReached(true);
			}

		} catch (error) {
			console.error("Error during signup:", error);
		}
		setCallInProgress(false)
		// 
		return
	}
	const gradientBackground = {
		backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)"
	};
	return (
		<motion.div className="md:-mt-8 w-full overflow-none bg-[#15141D] text-neutral-700 flex flex-col items-center " style={gradientBackground}>

			{/* Header */}
			<div className="absolute md:top-8 top-2 right-4 md:right-32 md:text-md text-xs  border border-gray-800 hover:bg-gray-800 hover:text-white z-[999999] px-4 py-2 cursor-pointer" onClick={() => { window.location.href = "/login" }}>Login</div>

			<div className="flex flex-col md:w-full w-full items-center my-auto mx-auto h-full overflow-none z-50 md:mt-0 mt-4">

				<div className="flex flex-col  my-auto w-full">
					<h1 className="md:text-6xl text-4xl font-semibold text-black text-gray-950  text-left md:mt-4 my-auto  text-center"> <img className="w-96 mx-auto" src={bwb} /> </h1>


					<div className="mx-auto w-full md:-mt-6">

						<p className="md:text-2xl text-md font-light leading-relaxed text-center text-gray-900">
							AI Phone Calling with 5 lines of code.
							<br></br>
							<span className="italic md:text-xl text-md">Anyone. Anywhere. Any objective.</span>

						</p>
						{/* </motion.section> */}





					</div>
					{/* Code Snippet */}
					<div className="flex flex-col md:w-3/5 w-full mx-auto mt-12 relative h-full">

						{/* <div className="mt-4 text-white my-2 md:text-xl text-xs text-center bg-neutral-800 py-2">
							<span className="text-red-400 border border-gray-100 rounded-sm p-1 md:text-sm">POST</span> <span className="my-auto ml-2">https://api.callbelva.com</span> </div>
 */}
						<img className="w-3/4 mx-auto hover:shadow-2xl hover:shadow-gray-400 transition-all duration-500 hover:cursor-pointer z-[900]" src={code}></img>

						{/* <SyntaxHighlighter className="bg-opacity-0  rounded-none shadow-sm text-xs" language="json" style={railscasts}>
							{codeSnippet.toString()}
						</SyntaxHighlighter> */}
					</div>
					<div className="flex flex-col md:flex-row md:w-1/3 w-2/3 my-12 md:space-x-2 mx-auto space-y-2 md:space-y-0">
						<button className="md:w-3/4 hover:w-full w-full border border-gray-500 px-2 py-2 text-xl text-gray-500 transition-all duration-500" onClick={() => { window.location.href = "/signup" }}>Signup</button>
						<button className="md:w-3/4 hover:w-full w-full border border-purple-300 px-2 py-2 font-mono hover:border-purple-400 hover:bg-purple-400 bg-purple-300   hover:text-purple-400 duration-500 transition-all" onClick={() => { window.location = "https://respell.ai/register" }}>
							<img src={respell_logo} className="w-38 h-8 mx-auto" /></button>


					</div>
				</div>
			</div>
			<div className="w-full bg-neutral-100 flex flex-col border-t border-gray-50 ">


				<div className="flex mx-auto w-full flex-col mt-2 mb-4 py-6  bg-black text-white">


					<div className="pb-2 mt-6  text-5xl w-full text-center mx-auto font-light text-opacity-80">Have Belva call you, right now.</div>

					<div className="flex flex-col md:flex-row md:w-1/2 w-4/5 mx-auto my-12">
						{!callInProgress && <div className="flex w-full mx-auto space-y-4 md:space-y-0 flex-col md:flex-row md:space-x-2">
							<input
								ref={phoneNumber}
								placeholder="Phone Number"
								className="placholder-gray-100 border border-white-900 bg-opacity-0 bg-gray-900 text-white w-full mx-auto px-2 py-2"
								pattern="[0-9]{10}"
								maxLength="10"

							/>
							<button className="py-2 border border-white-900 w-full md:w-1/2 hover:text-white hover:bg-gray-900 hover:text-gray-900 duration-500 transition-all flex text-center" onClick={() => { issueWelcomeCall() }}><span className="mx-auto flex">Call Me <FiPhone className="my-auto mx-2" /></span></button>
						</div>}
						{callInProgress && <>
							<button className="mx-auto border border-gray-900 w-1/3 hover:bg-gray-900 hover:text-gray-900 duration-500 transition-all" onClick={() => { }}>Sending.</button> </>}

					</div>
				</div>


				{/* Common Use Cases */}
				<div className="h-full md:w-1/2 w-5/6 mx-auto text-lg font-light text-center z-5 ">
					<div className="flex flex-col items-center justify-center via-indigo-700 to-blue-500 text-gray-900">
						<div className="md:p-5 rounded-xl bg-opacity-5 backdrop-blur-lg backdrop-filter">

							<h1 className="text-4xl font-bold text-center mb-4">Welcome to Belva.</h1>

							<p className="text-lg text-center mb-12">
								Beta access on Respell.
							</p>



							<div className="flex md:space-x-12 space-x-1 w-full mb-4 justify-evenly text-gray-900 ">
								<div className="flex flex-col text-center border border-gray-900 md:w-1/3 w-full h-48 justify-center">
									<RxLapTimer className="text-3xl mb-2 mx-auto" />
									<p className="md:text-xs text-xs w">HoldOn</p>
									<p className="w-2/3 text-lg py-2 text-center mx-auto">Never wait on hold again.</p>
								</div>
								<div className="flex flex-col text-center border border-gray-900 md:w-1/3 w-full h-48 justify-center">
									<FiPhoneOff className="text-3xl mb-2 mx-auto" />
									<p className="md:text-xs text-xs w">Outbound AI Calls</p>
									<p className="w-2/3 text-lg py-2 text-center mx-auto">Call anyone.</p>
								</div>

								<div className="flex flex-col text-center border border-gray-900 md:w-1/3 w-full h-48 justify-center">
									<MdVoicemail className="text-3xl mb-2 mx-auto" />
									<p className="md:text-xs text-xs w">VMA</p>
									<p className="w-2/3 text-lg py-2 text-center mx-auto">Leave AI voicemails.</p>
								</div>
							</div>

							<p className="text-lg text-center mb-12 mt-12">
								Join now for early access, available only for a limited set of users.
							</p>

							<button onClick={() => { window.location.href = "/signup" }} className="w-full px-4 py-2 bg-white hover:bg-gray-900 hover:text-white bg-opacity-0 text-gray-900 font-light border border-gray-900 duration-500  mt-5">
								Signup
							</button>
							<button onClick={() => { window.open("https://respell.ai/register") }} className="w-full px-4 py-2 bg-purple-400 text-white border-purple-400 border font-light hover:bg-purple-800 hover:text-white duration-500 mt-5 flex items-center text-center">
								<div className="flex mx-auto space-x-2">
									<span className="">Try On</span> <img className="w-24 " src={respell_logo}></img>
								</div>
							</button>
						</div>
					</div>

				</div>

				{/* FAQ */}
				<motion.section
					className="w-full md:w-8/12 md:mt-0 mt-12 lg:w-6/12 md:mt-12 text-center mx-auto"
					variants={sectionVariants}
					initial="initial"
					animate="animate"
				>
					<h2 className="text-4xl font-bold mb-8">FAQ</h2><div className="md:text-2xl text-sm leading-relaxed text-left space-y-4">
						<FAQItem question="Can I call anyone with any objective?" answer="Absolutely. With our flexible API, you can program Belva to initiate outbound calls with any objective, intent, or script that you specify. Want to book a doctor's appointment or restaurant reservation? Order food for delivery? Check your account balance? The possibilities are endless. Belva will follow your custom script and conversations flows to accomplish nearly any calling objective." /> <FAQItem question="Can Belva wait on hold?" answer="Definitely. One of Belva's key features is her ability to seamlessly navigate phone menus, including waiting on hold when needed. When she encounters a hold message or music, Belva will patiently remain on the line until a human representative becomes available. She can wait through multiple cycles of menus, prompts, and hold times to ensure she reaches the right department or person. This saves you time and frustration." /> <FAQItem question="Can Belva remember information during the call?" answer="Absolutely. Belva has robust memory and recall abilities to retain pertinent details throughout a call. For example, if you want her to book a doctor's appointment, she will remember the doctor's name, preferred date/time, reason for visit, and any other provided details. When speaking with the receptionist, Belva can then recall this information to efficiently complete the booking just like a human would." /> <FAQItem question="Can Belva press buttons during a call?" answer="Yes! Belva can effectively navigate touch-tone menus, voicemail prompts, automated attendants, and any other phone system by pressing appropriate keys in response to audio prompts. She has highly advanced speech recognition and language processing capabilities to understand which button options to select in order to get to the right department or menu. This allows her to have natural conversations over the phone just like a person." /> </div>
				</motion.section>
			</div>



			{/* Footer */}
			<footer className="w-full p-6 text-center border-t border-gray-800 flex flex-col  py-8">
				<div className="flex w-full">


					<div className="text-sm mx-auto">© {new Date().getFullYear()} Belva - All rights reserved.</div>
					<AiOutlineTwitter className="mx-auto text-xl cursor-pointer hover:text-blue-400" onClick={() => { window.open("https://twitter.com/BelvaInc") }} />
				</div>
				<div>All users are subject to our <a href={belvaTOS} className="underline text-xs">Terms Of Service.</a></div>
			</footer>
		</motion.div >

	);
};

export default LandingPage;
