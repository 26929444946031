import { useRef, useState } from "react";
import { useSpring, animated } from 'react-spring';
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { monokaiSublime, railscasts } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { motion } from "framer-motion";
import belva_logo from "../assets/belva_logo.png";
import './xss.css';  // Custom CSS
import useTypingEffect from "./typing";
import axios from "axios";
import "./xss.css"
import { AiOutlineTwitter } from "react-icons/ai"
import respell_logo from "../assets/white.png"
import { FaTelegramPlane } from "react-icons/fa"
import { FiMessageCircle } from "react-icons/fi"
import { GiPhone } from "react-icons/gi"
import code from "../assets/carbon.png"
const codeSnippet = ` {
	"phone_number": "7349303030", 
	"objective": "Order me a pizza with pepperoni to deliver to the Marina.", 

	"params": {
	  "calling_for":"Dominoes",
	  "toppings":"Pepperoni"
	}
	
}`;

const sectionVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
};

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="mb-4">
            <button
                className="w-full flex justify-between items-center py-4 px-6  text-gray-900 md:text-xl text-sm font-bold rounded-none focus:outline-none"
                onClick={toggleAccordion}
            >
                <span>{question}</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-6 w-6 transform ${isOpen ? "rotate-180" : "rotate-0"}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </button>
            {isOpen && (
                <div className="mt-2 p-4 bg-gray-200 text-gray-800 rounded-none glass">
                    <p className="text-lg">{answer}</p>
                </div>
            )}
        </div>
    );
};

const DarkLandingPage = () => {
    const phoneNumber = useRef(null)
    const [callInProgress, setCallInProgress] = useState(false)

    const useCases = [
        {
            title: "Restaurant Reservations",
            details: "Harness the power of Belva to secure reservations at your favorite dining spots. With automated calling, your reservation is just a click away."
        },
        {
            title: "Healthcare Appointments",
            details: "Leverage Belva's capabilities to schedule your next doctor's appointment. Health made easy and accessible."
        },
        {
            title: "E-commerce Orders",
            details: "Streamline your e-commerce business by using Belva for automated order confirmations. Enhance your business efficiency and customer service."
        },
        // ... Add more use cases
    ];

    const [selectedUseCase, setSelectedUseCase] = useState(null);
    const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1];
    const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
    const fadeIn = useSpring({
        from: { opacity: 0, marginTop: -500 },
        to: { opacity: 1, marginTop: 0 },
        config: { duration: 1000 }
    });
    const typedBelva = useTypingEffect("Belva", 100);  // 100ms delay between each character
    const issueWelcomeCall = async () => {
        setCallInProgress(true)
        try {
            const response = await axios.post('/api/signup/welcomeCall', { phoneNumber: phoneNumber?.current?.value });


        } catch (error) {
            console.error("Error during signup:", error);
        }
        setCallInProgress(false)

        return
    }
    const gradientBackground = {
        backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)"
    };
    const bgColor = "bg-neutral-950"
    const baseTextColor = "text-white"
    return (
        <motion.div className={`w-full overflow-none bg-neutral-950 text-neutral-700 flex flex-col items-center `}>

            {/* Header */}
            <img className={`md:w-24 absolute top-2 md:h-24 w-12 h-12 rounded-full bg-gray-900 p-4 border border-gray-900`} src={belva_logo}></img>

            <div className={`flex flex-col md:w-full w-full items-center my-auto mx-auto h-full overflow-none z-50 md:mt-0 mt-24`}>

                <div className={`flex flex-col  my-auto w-full`}>
                    <h1 className={`md:text-8xl text-4xl font-semibold mb-6 text-black text-gray-950  text-left md:mt-52 my-auto  text-center gradient-text`}> Belva Labs.</h1>


                    <div className={`mx-auto w-full`}>

                        <p className={`md:text-2xl text-md font-light leading-relaxed text-center text-white`}>
                            AI Phone Calling with 5 lines of code.
                            <br></br>
                            <span className={`italic md:text-xl text-md`}>Anyone. Anywhere. Any objective.</span>

                        </p>
                        {/* </motion.section> */}



                        <div className={`flex flex-col md:flex-row md:w-1/3 w-2/3 my-12 md:space-x-2 mx-auto space-y-2 md:space-y-0`}>
                            <button className={`md:w-3/4 hover:w-full w-full border border-gray-500 px-2 py-2 text-xl text-gray-500 transition-all duration-500`}>Join Waitlist</button>
                            <button className={`md:w-3/4 hover:w-full w-full border border-gray-500 px-2 py-2 font-mono hover:border-purple-400 hover:bg-purple-400 bg-purple-300   hover:text-purple-400 duration-500 transition-all`} onClick={() => { window.location = "https://respell.ai" }}>
                                <img src={respell_logo} className={`w-38 h-8 mx-auto`} /></button>


                        </div>

                    </div>
                    {/* Code Snippet */}
                    <div className={`flex flex-col md:w-3/5 w-full mx-auto mt-12 relative h-full`}>

                        {/* <div className="mt-4 text-white my-2 md:text-xl text-xs text-center bg-neutral-800 py-2">
                            <span className="text-red-400 border border-gray-100 rounded-sm p-1 md:text-sm">POST</span> <span className="my-auto ml-2">https://api.callbelva.com</span> </div>
         */}
                        <img className={`hover:shadow-2xl hover:shadow-gray-400 transition-all duration-500 hover:cursor-pointer z-[900]`} src={code}></img>

                        {/* <SyntaxHighlighter className="bg-opacity-0  rounded-none shadow-sm text-xs" language="json" style={railscasts}>
                            {codeSnippet.toString()}
                        </SyntaxHighlighter> */}
                    </div>
                </div>
            </div>
            <div className={`w-full ${bgColor} flex flex-col border-t border-gray-50 `}>


                <div className={`flex mx-auto w-2/3 flex-col my-8`}>


                    <div className={`pb-2 mt-8  text-5xl w-full text-center mx-auto font-light text-opacity-80`}>Hear directly from Belva.</div>

                    <div className={`flex flex-col md:w-1/2 w-4/5 mx-auto my-12`}>
                        {!callInProgress && <div className={`flex w-full mx-auto space-y-4 flex-col`}>
                            <input ref={phoneNumber} placeholder="Phone Number" className={`border border-gray-900 bg-opacity-0 bg-gray-900 w-full mx-auto px-2 py-2`}>
                            </input>
                            <button className={`py-2 border border-gray-900 w-full hover:text-white hover:bg-gray-900 hover:text-gray-900 duration-500 transition-all`} onClick={() => { issueWelcomeCall() }}>Call</button>
                        </div>}
                        {callInProgress && <>
                            <button className={`mx-auto border border-gray-900 w-1/3 hover:bg-gray-900 hover:text-gray-900 duration-500 transition-all`} onClick={() => { }}>Sending.</button> </>}

                    </div>
                </div>


                {/* Common Use Cases */}
                <div className={`h-full md:w-1/2 w-5/6 mx-auto text-lg font-light text-center ${bgColor} z-5 `}>
                    <div className={`flex flex-col items-center justify-center via-indigo-700 to-blue-500 text-gray-900`}>
                        <div className={`mx-auto w-full mt-12 text-2xl font-semibold `}>
                            Common Use Cases
                        </div>
                        <div className={`flex flex-col mt-8 items-center justify-center`}>
                            {useCases.map((x, i) => {
                                return <div key={i} className={`flex flex-col border border-gray-900 rounded-lg mt-4 p-4 w-3/4 text-center  items-center justify-center `}>
                                    <div className={`text-xl mb-2 `}>{x.title}</div>
                                    <div className={`text-sm text-gray-700 font-light`}>{x.description}</div>
                                </div>
                            })}

                        </div>
                    </div>
                </div>

                {/* FAQ */}

                {/* Contact Section */}
                <div className={`w-full flex flex-col items-center mx-auto text-gray-700`}>
                    <div className={`md:w-2/3 w-3/4 mx-auto flex flex-col text-center `}>
                        <h3 className={`text-2xl mt-12 mx-auto font-semibold w-full`}>
                            Contact Us
                        </h3>
                        <p className={`text-lg mt-4`}>
                            Send us an email, a tweet or simply give us a call.
                        </p>
                        <div className={`flex md:flex-row flex-col mt-8 space-y-4 md:space-y-0 md:space-x-4`}>
                            <div className={`md:w-1/3 w-full flex flex-col items-center justify-center py-4 border border-gray-900 rounded-lg`}>
                                <AiOutlineTwitter size={30} />
                                <div className={`text-xl mt-2`}>Tweet Us</div>
                                <div className={`text-md font-light`}>@Belva</div>
                            </div>
                            <div className={`md:w-1/3 w-full flex flex-col items-center justify-center py-4 border border-gray-900 rounded-lg`}>
                                <FaTelegramPlane size={30} />
                                <div className={`text-xl mt-2`}>Message Us</div>
                                <div className={`text-md font-light`}>hello@Belva.com</div>
                            </div>
                            <div className={`md:w-1/3 w-full flex flex-col items-center justify-center py-4 border border-gray-900 rounded-lg`}>
                                <FiMessageCircle size={30} />
                                <div className={`text-xl mt-2`}>Telegram</div>
                                <div className={`text-md font-light`}>@Belva</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </motion.div>
    )
}
export default DarkLandingPage;