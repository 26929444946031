import { useEffect, useRef, useState } from "react";
import PhoneNumberInput from "../components/PhoneInput";
import InputForm from "../components/InputForm";

const HomePage = () => {
	const nameRef = useRef();
	const phoneNumber = useRef();
	const agentGoalRef = useRef();
	const [agentGoal, setAgentGoal] = useState("");
	const [wordCount, setWordCount] = useState(0);
	const [transcript, setTranscript] = useState([]);
	const inpSty = "w-1/2 bg-gray-700 rounded-md py-2 px-2 focus:outline-none text-gray-200";

	const endOfMessagesRef = useRef(null); // Reference to the last message

	const handleAgentGoalChange = (e) => {
		console.log("test");
		if (e.target.value.split(/\s+/).length - 1 <= 100) {
			setAgentGoal(e.target.value);
		}
	};

	useEffect(() => {
		setWordCount(agentGoal?.split(/\s+/).length - 1);
	}, [agentGoal]);

	// Scroll to the last message whenever transcript updates
	useEffect(() => {
		if (endOfMessagesRef.current) {
			endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [transcript]);

	return (
		<div className="w-full h-screen bg-gray-100 flex text-gray-200">
			<div className="flex w-1/2">
				<InputForm transcript={transcript} setTranscript={setTranscript} />
			</div>
			<div className="w-full flex flex-col overflow-y-scroll h-full space-y-2">
				<div className="flex flex-col overflow-y-scroll my-auto h-5/6 bg-gray-100 w-2/3 mx-auto border-[0.2px] border-gray-400">
					{transcript?.map((key, idx) => {
						return (
							<div className="rounded-md p-2 my-1 font-mono text-gray-500">
								<span className={key?.said_by === "human" ? "text-purple-500" : " text-green-400" + " pr-2"}>{key?.said_by}</span>: {key?.text}
							</div>
						)
					})}
					<div ref={endOfMessagesRef} />
				</div>
			</div>
		</div>
	);
};

export default HomePage;
