import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { VscLoading } from "react-icons/vsc"
const fetcher = (url, callId) =>
    axios.post(
        url,
        { call_id: callId },
        { headers: { 'Content-Type': 'application/json', 'x-api-key': 'cb-xyz42069' } }
    ).then(res => console.log(res));

const buttonVariants = {
    hover: { scale: 1.1, transition: { duration: 0.3 } },
    press: { scale: 0.9 }
};

const formVariants = {
    hidden: { opacity: 0, y: '-100vh' },
    visible: { opacity: 1, y: 0, transition: { type: 'spring', delay: 0.5 } },
    exit: { opacity: 0, y: '100vh', transition: { ease: 'easeInOut' } }
};
const FullScreenAnimation = ({ setIsAnimating }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsAnimating(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, [setIsAnimating]);

    return (
        <div className="flex flex-col absolute w-full h-screen z-50"
        >
            <div className="w-1/2 h-1/2 mx-auto my-auto flex flex-col bg-slate-900 bg-opacity-5 backdrop-filter backdrop-blur-sm shadow-2xl rounded-sm">
                <div className="mx-auto my-auto flex flex-col text-4xl">

                    <span className="mx-auto my-auto"> Initiating Call...</span>
                    <VscLoading className="mx-auto animate-spin mt-4" />
                </div>
            </div>


        </div>
    );
};

const InputForm = ({ setTranscript, transcript, api_key }) => {
    const [phone_number, setPhoneNumber] = useState('');
    const [objective, setObjective] = useState('');
    const [params, setParams] = useState([{}]);
    const [callId, setCallId] = useState(null);
    const [callStarted, setCallStarted] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);
    // Rest of your code...



    // Other state and variables...

    const formatPhoneNumber = (input) => {
        // Remove all non-numeric characters
        return input.replace(/\D/g, '');
    }
    const handleParamChange = (idx, e) => {
        const newParams = [...params];
        if (e.target.name === "key") {
            newParams[idx][e.target.name] = e.target.value.replace(/\s+/g, '');
        } else {
            newParams[idx][e.target.name] = e.target.value;
        }
        setParams(newParams);
    };

    const addParam = () => {
        if (params.length < 5) {
            setParams([...params, {}]);
        } else {
            alert('You can only add up to 5 parameters');
        }
    };

    const removeParam = (idx) => {
        const newParams = [...params];
        newParams.splice(idx, 1);
        setParams(newParams);
    };

    useEffect(() => {
        let intervalId; // Declare a variable to keep the interval ID.

        if (callStarted) {
            intervalId = setInterval(async () => { // Assign the interval ID to the variable.
                try {
                    const response = await axios?.post('https://api.callbelva.com/transcriptions',
                        { call_id: callId }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': api_key
                        }
                    });
                    setTranscript(response?.data?.transcripts)
                    if (response?.data?.is_done) {
                        clearInterval(intervalId); // Clear the interval using the interval ID.
                    }
                } catch (error) {
                    clearInterval(intervalId); // Clear the interval using the interval ID.
                    console.error(error)
                }
            }, 2000);
        } else {
            if (intervalId) { // Check if intervalId is not undefined.
                clearInterval(intervalId); // Clear the interval using the interval ID.
            }
        }

        return () => { // Clean up function that React will run when a component unmounts.
            if (intervalId) {
                clearInterval(intervalId);
            }
        };

    }, [callStarted, callId, setTranscript])

    const endCall = async () => {
        setCallStarted(false);
    }

    const startCall = async () => {
        setIsAnimating(true)
        const paramsObj = params.reduce((acc, param) => ({ ...acc, [param.key]: param.value }), {});
        try {
            const response = await axios.post('https://api.callbelva.com/call',
                { phone_number, objective, params: paramsObj }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key
                }
            });
            if (response.data.call_id) {
                setCallId(response.data.call_id);
                setCallStarted(true)
            } else {
                alert('Error starting the call');
            }
        } catch (error) {
            alert('Error in axios')
            clearInterval()
        }
    };


    return (
        <AnimatePresence>
            {isAnimating && <FullScreenAnimation setIsAnimating={setIsAnimating} />}
            <motion.div className="w-full max-w-lg mx-12 px-2 py-4  bg-opacity-100 backdrop-filter backdrop-blur-md rounded-none bg-white my-auto  mt-16 shadow-xl text-gray-800"
                variants={formVariants}
                // initial="hidden"
                // animate="visible"
                exit="exit"
            >
                <div className="mb-3 space-y-2">
                    <label className="text-sm font-medium tracking-wide ">Phone Number</label>
                    <input className=" w-full text-base px-4 py-2 border border-gray-600 focus:outline-none focus:border-gray-500 bg-transparent " type="text" value={phone_number} onChange={e => setPhoneNumber(formatPhoneNumber(e.target.value))} />
                </div>
                <div className="mb-3 space-y-2">
                    <label className="text-sm font-medium  tracking-wide">Objective</label>
                    <textarea className=" w-full text-base px-4 py-2 border border-gray-600 focus:outline-none focus:border-gray-500 bg-transparent " type="text" value={objective} onChange={e => setObjective(e.target.value)} />
                </div>
                {params.map((param, idx) => (
                    <div key={idx} className="mb-3 space-y-2">
                        <div className="flex items-center justify-between">
                            <div className="w-1/2 mr-2 space-y-1">
                                <label className="text-sm font-medium  tracking-wide">Key {idx + 1}</label>
                                <input className=" w-full text-base px-4 py-2 border border-gray-600 focus:outline-none focus:border-gray-500 bg-transparent " type="text" name="key" value={param.key || ''} onChange={e => handleParamChange(idx, e)} />
                            </div>

                            <div className="w-1/2 ml-2 space-y-1 flex flex-col">
                                <label className="text-sm font-medium  tracking-wide">Value {idx + 1}</label>
                                <div className="flex">
                                    <input className=" w-full text-base px-4 py-2 border border-gray-600 focus:outline-none focus:border-gray-500 bg-transparent " type="text" name="value" value={param.value || ''} onChange={e => handleParamChange(idx, e)} />
                                    <button onClick={() => removeParam(idx)} className="ml-2 py-1 px-3 bg-red-500 text-white text-sm rounded hover:bg-red-600 my-auto">X</button>

                                </div>

                            </div>
                        </div>
                    </div>
                ))}
                <div className="mt-5 text-right">
                    {params.length < 5 &&
                        <motion.button whileHover={buttonVariants.hover} whileTap={buttonVariants.press} onClick={addParam} className="py-2 px-4 bg-gray-700 text-white font-semibold rounded-none shadow-md hover:bg-gray-600 focus:outline-none transform transition duration-500 ease-in-out hover:scale-110">
                            Add Param
                        </motion.button>}
                    <motion.button whileHover={buttonVariants.hover} whileTap={buttonVariants.press} onClick={startCall} className="py-2 px-4 bg-gray-700 text-white font-semibold rounded-none shadow-md hover:bg-gray-600 focus:outline-none ml-2 transform transition duration-500 ease-in-out hover:scale-110">
                        Start Call
                    </motion.button>
                    <motion.button whileHover={buttonVariants.hover} whileTap={buttonVariants.press} onClick={endCall} className="py-2 px-4 bg-gray-700 text-white font-semibold rounded-none shadow-md hover:bg-gray-600 focus:outline-none ml-2 transform transition duration-500 ease-in-out hover:scale-110">
                        Stop Call
                    </motion.button>
                </div>
            </motion.div>
        </AnimatePresence>
    );

}
export default InputForm