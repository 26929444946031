import {
    PaymentElement,
    LinkAuthenticationElement
} from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { PiSpinnerGapLight } from "react-icons/pi"
export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/lab`,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsLoading(false);
    }

    return (
        <form className="w-11/12 mx-auto relative" onSubmit={handleSubmit}>
            <LinkAuthenticationElement id="link-authentication-element"
            // Access the email value like so:
            // onChange={(event) => {
            //  setEmail(event.value.email);
            // }}
            //
            // Prefill the email field like so:
            // options={{defaultValues: {email: 'foo@bar.com'}}}
            />
            <PaymentElement className="bg-white z-[99999999] mt-4 flex flex-col shadow appearance-none border rounded w-full h-full px-4 py-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            <button disabled={isLoading || !stripe || !elements} id="submit" className="mt-12">
                <button className="w-full bg-white px-12 py-4 mt-8 rounded-sm border border-gray-300">
                    {isLoading ? <PiSpinnerGapLight className=" animate-spin w-full rounded-full w-5 h-5 rounded-lg" /> : <div>Pay Now</div>}

                </button>
            </button>
            {/* Show any error or success messages */}
            {message && <div className="my-8 " id="payment-message">{message}</div>}
        </form >
    )
}