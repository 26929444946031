import Payment from "./PaymentPage"

const PayMore = ({ }) => {
    return (
        <div className="w-full h-screen flex bg-white">
            <div className="w-1/2 mx-auto my-auto rounded-md border border-gray-300 py-2">
                <div className="text-center py-4 text-2xl">Add $5 of credits (25 minutes of call time).</div>
                <div className="text-xs w-1/2 text-center mx-auto">Because we are in Beta, we are limiting how many credits can be added at any given time. <br></br>We've temporarily raised prices because of increased demand to 5c / minute.</div>
                <Payment />
            </div>


        </div>
    )
}

export default PayMore