import React, { useState } from 'react';

const SignUpForm = () => {
    const [formState, setFormState] = useState({
        name: '',
        email: '',
        title: '',
        company: '',
        additional_comments: ''
    });

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        const response = await fetch('/api/waitlist', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formState)
        });

        setLoading(false);

        if (response.ok) {
            setFormState({
                name: '',
                email: '',
                title: '',
                company: '',
                additional_comments: ''
            });
            setMessage("Thanks for joining the waitlist! Check out Respell.AI for early access.");
            setTimeout(() => {
                window.location.href = "/"
            }, 8000)
        } else {
            setMessage("Error signing up");
        }
    };

    return (
        <div className="min-h-screen bg-neutral-300 flex flex-col justify-center items-center">
            <h1 className="text-4xl font-bold mb-5 text-gray-800">Belva Labs Waitlist.</h1>

            {message && <p className="text-green-500 mb-2">{message}</p>}

            <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto p-5 bg-neutral-200 shadow-lg">
                <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formState.name}
                    onChange={handleChange}
                    className="w-full mb-4 p-3 border border-gray-300 rounded-none"
                />

                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formState.email}
                    onChange={handleChange}
                    className="w-full mb-4 p-3 border border-gray-300 rounded-none"
                    required
                />

                <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formState.title}
                    onChange={handleChange}
                    className="w-full mb-4 p-3 border border-gray-300 rounded-none"
                />

                <input
                    type="text"
                    name="company"
                    placeholder="Company"
                    value={formState.company}
                    onChange={handleChange}
                    className="w-full mb-4 p-3 border border-gray-300 rounded-none"
                />

                <textarea
                    name="additional_comments"
                    placeholder="Additional comments"
                    value={formState.additional_comments}
                    onChange={handleChange}
                    className="w-full mb-4 p-3 border border-gray-300 rounded-none"
                />

                <button type="submit" className="w-full py-2 px-4 rounded-none bg-gray-800 text-white hover:bg-gray-700 transition-colors duration-200 ease-in-out" disabled={loading}>
                    {loading ? "Loading..." : "Sign Up"}
                </button>
            </form>
        </div>
    );
};

export default SignUpForm;
