import React, { useEffect, useState } from "react";
import axios from 'axios';
import belva_log from "../assets/belva_logo.png"
import { AiOutlineLoading } from 'react-icons/ai';

function LoginPage() {
    const [loginMethod, setLoginMethod] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [error, setError] = useState("");
    const [didSendCode, setDidSendCode] = useState(false)
    const [partIsLoading, setPartIsLoading] = useState(false)
    const [verificationSent, setVerificationSent] = useState(false)
    const handleLoginMethod = (method) => {
        setLoginMethod(method);
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            if (loginMethod === 'email') {
                const res = await axios.post("/api/login/login", { email, password });
                localStorage.setItem("token", res.data.token);
                window.location.href = "/lab"
            }
        } catch (err) {
            setError("Failed to login");
        }
    };
    const verifyUser = async () => {
        if (localStorage.getItem('token')) {
            const headers = {
                'Authorization': `${localStorage.getItem('token')}`
            }
            try {
                const response = await axios.get('/api/verify', { headers });
                localStorage.setItem("token", response.data.token);
                window.location.href = "/lab"
            } catch (error) {
                // localStorage.clear()
            }


        }
    }
    useEffect(() => {
        verifyUser()
    }, [])
    // Simulate API call
    const sendCode = async () => {
        if (didSendCode) {
            return
        } else {


            try {

                const response = await axios.post('/api/signup/smsverification', { phoneNumber: phoneNumber });
                setDidSendCode(true)

            } catch (error) {
                console.error("Error during signup:", error);
            }
            return
        }
    }
    const verifySMS = async () => {
        try {
            const response = await axios.post('/api/login/smslogin', { code: verificationCode, phoneNumber: phoneNumber });
            console.log(response.data); // Handle response data as needed.
            localStorage.setItem("token", response.data.token)
            window.location = "/lab"
        } catch (error) {
            console.error("Error during signup:", error);
        }
    }

    const inpSty = "mx-auto  bg-opacity-0 bg-white my-2 w-full  transition-all duration-300 focus:outline-none border border-gray-800 py-2 px-2 text-gray-800"

    return (
        <div className="min-h-screen flex bg-neutral-200 py-12 px-6 lg:px-8">
            <div className=" mx-auto h-1/2 md:w-1/3 w-full my-auto bg-white space-y-8 shadow-2xl bg-opacity-30  p-10 backdrop-filter backdrop-blur-lg">
                <div className="-mb-4">
                    <div className="text-center text-4xl my-2">Belva AI.</div>

                    <h2 className=" text-center text-xl text-gray-800">
                        Sign in.
                    </h2>
                    {error && <div className="text-red-500 mt-2 text-sm">{error}</div>}
                    {loginMethod === "" && <div>
                        <button onClick={() => handleLoginMethod('email')} className="mt-4 group relative w-2/3 md:w-1/2 mx-auto  flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium  text-gray-800 hover:text-white  hover:bg-gray-800 hover:text-gray-900 transition-all duration-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Sign in with Email
                        </button>
                        <button onClick={() => { handleLoginMethod('phone') }} className="mt-4 group relative w-2/3 md:w-1/2 mx-auto flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium  text-gray-800 hover:text-white  hover:bg-gray-800 hover:text-gray-900 transition-all duration-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Sign in with Phone
                        </button>
                    </div>}
                </div>
                <form className="space-y-6 flex flex-col" onSubmit={handleLogin}>
                    <input type="hidden" name="remember" value="true" />
                    <div className="rounded-md shadow-sm flex flex-col w-full ">
                        {loginMethod === 'email' &&
                            <>
                                <div className="w-full flex">
                                    <label htmlFor="email-address" className="sr-only">Email address</label>
                                    <input id="email-address" name="email" type="email" autoComplete="email" required className={inpSty} placeholder="Email address" onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className="w-full flex">
                                    <label htmlFor="password" className="sr-only">Password</label>
                                    <input id="password" name="password" type="password" autoComplete="current-password" required className={inpSty} placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                </div>
                                <button type="submit" className=" bg-slate-800 mt-2 group relative w-1/2 mx-auto hover:w-full flex justify-center py-2 px-4 border text-sm font-medium  text-white  hover:bg-slate-800 hover:text-white transition-all duration-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Sign in
                                </button>
                            </>}
                        {loginMethod === 'phone' &&
                            <>
                                {!didSendCode ? (<div className="w-full flex flex-col">
                                    <label htmlFor="phone-number" className="sr-only">Phone number</label>
                                    <input id="phone-number" name="phone" type="tel" required className={inpSty} placeholder="Phone number" onChange={e => setPhoneNumber(e.target.value)} onBlur={e => sendCode(e.target.value)} />
                                    <button onClick={async () => {
                                        setPartIsLoading(true);
                                        await sendCode()
                                        setPartIsLoading(false)
                                    }} className="flex mt-4 group relative w-1/2 mx-auto hover:w-full flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium  text-white  bg-slate-800 hover:text-white transition-all duration-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        {partIsLoading ? <span className="mx-auto my-auto text-center"><AiOutlineLoading className="animate-spin text-white" /> </span> : "Send Code"}
                                    </button>
                                </div>) : (
                                    <div className="w-full flex mt-8 flex-col">
                                        <label htmlFor="verification-code" className="sr-only">Verification Code</label>
                                        <input id="verification-code" value={verificationCode} name="code" type="text" className={inpSty} placeholder="Verification Code" onChange={e => setVerificationCode(e.target.value)} />
                                        <button onClick={() => { verifySMS() }} className="mt-2 group relative w-1/2 mx-auto hover:w-full bg-gray-800 text-white flex justify-center py-2 px-4 border text-sm font-medium  text-gray-800  hover:bg-gray-800   transition-all duration-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Sign in
                                        </button>
                                    </div>
                                )}
                            </>}
                    </div>
                    <div>

                    </div>
                </form>
            </div>
            <div className="absolute bottom-4 w-full text-center flex"><span className="mx-auto text-slate-600 w-1/3 text-sm">We are still early in our journey. Having issues? Email us at hello@belva.ai or find us on twitter @BelvaInc</span></div>

        </div>
    );
}

export default LoginPage;
