import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage.js";
import LandingPage from "./pages/LandingPage.js";
import Signup from "./pages/SignUp.js";
import Payment from "./pages/PaymentPage.js";
import LoginPage from "./pages/LoginPage.js";
import Main from "./pages/MainPage.js";
import PayMore from "./pages/PayMore.js";
import DarkLandingPage from "./pages/DarkLandingPage.js";
import SignUpForm from "./pages/Waitlist.js";

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<LandingPage />}></Route>
				<Route path="/home" element={<HomePage />}></Route>
				<Route path="/signup" element={<Signup />}></Route>
				<Route path="/pay" element={<PayMore />}></Route>
				<Route path="/login" element={<LoginPage />}></Route>
				<Route path="/lab" element={<Main />}></Route>
				<Route path="/dark" element={<DarkLandingPage />}></Route>
				<Route path="/waitlist" element={<SignUpForm />}></Route>




			</Routes>
		</BrowserRouter>
	);
};
export default App;
