import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Payment from './PaymentPage';
import "./xss.css"
import { AiOutlineLoading } from 'react-icons/ai';
import PhoneInput from 'react-phone-number-input'
import belvaTOS from "../assets/BELVATOS.docx"
function Signup() {
    const [form, setForm] = useState({ firstName: '', lastName: '', email: '', password: '', phoneNumber: '', code: '' });
    const [isSignedUp, setSignedUp] = useState(false);
    const [signUpLoading, setSignUpLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [actionInProgress, setActionInProgress] = useState(false); // New state variable

    const [phJWT, setphJWT] = useState(null)
    const [welcomeCall, setWelcomeCall] = useState(true); // Initialize welcomeCall state
    const [callInProgress, setCallInProgress] = useState(false)
    const [stepLoading, setStepLoading] = useState(false)
    const [passwordsMatch, setPasswordsMatch] = useState(true)
    // ...rest of your code...

    const handleWelcomeCall = (response) => {
        setWelcomeCall(response);
        nextStep();
    }
    const verifyUser = async () => {
        if (localStorage.getItem('token')) {
            const headers = {
                'Authorization': `${localStorage.getItem('token')}`
            }
            try {
                const response = await axios.get('/api/verify', { headers });
                // localStorage.setItem("token", response.data.token);
                window.location.href = "/lab"
            } catch (error) {
                localStorage.clear()
            }


        }
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const formatPhoneNumber = (value) => {
        // Strip all characters from the input except digits
        let cleaned = ('' + value).replace(/\D/g, '');

        // Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            // If the input matches the pattern, format the number
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }

        return value;
    };

    const inpSty = "border  focus:outline-white outline-none transition-all duration-500 rounded-none py-2 px-3 text-gray-800 bg-none  "
    const handleSubmit = async (e) => {
        setActionInProgress(true); // Start action
        e?.preventDefault();

        try {
            const response = await axios.post('/api/signup/signup', form);
            localStorage.setItem("token", response.data.token)
            setSignedUp(true);
        } catch (error) {
            console.error("Error during signup:", error);
        }
        setActionInProgress(false); // End action
    }

    const sendSMSVerification = async () => {
        setActionInProgress(true); // Start action
        try {
            const response = await axios.post('/api/signup/smsverification', { phoneNumber: form?.phoneNumber });

        } catch (error) {
            console.error("Error during signup:", error);
        }
        setActionInProgress(false); // End action
        return;
    }

    const verifySMS = async () => {
        setActionInProgress(true); // Start action
        try {
            const response = await axios.post('/api/signup/verifysms', { code: form?.code, phoneNumber: form?.phoneNumber });
            setphJWT(response.data.jwt);
        } catch (error) {
            console.error("Error during signup:", error);
        }
        setActionInProgress(false); // End action
    }
    const issueWelcomeCall = async () => {
        try {
            setCallInProgress(true)
            const response = await axios.post('/api/signup/welcomeCall', { phoneNumber: form?.phoneNumber, firstName: form?.firstName });


        } catch (error) {
            setCallInProgress(false)
            console.error("Error during signup:", error);
        }
        return
    }

    useEffect(() => {
        verifyUser()
    }, [])
    const nextStep = () => {
        setStep(step + 1);
    }

    const prevStep = () => {
        setStep(step - 1);
    }
    const buttSty = "border border-white bg-gray-700 transition-all px-2 mx-auto mt-12 py-2"
    const renderStep = () => {

        switch (step) {

            case 1:
                return (
                    <div className="flex flex-col ">

                        <input
                            className={inpSty + " focus:w-full w-full border-b"}
                            type="text"
                            name="firstName"
                            value={form.firstName}
                            onChange={handleChange}
                            placeholder="First Name"
                            required
                        />
                        <input
                            className={" mt-4 z-50 " + inpSty + " focus:w-full w-full border-b "}
                            type="text"
                            name="lastName"
                            value={form.lastName}
                            onChange={handleChange}
                            placeholder="Last Name"
                            required
                        />


                        <a className="underline text-gray-900 z-50 mt-4 -mb-2 text-center" href={belvaTOS}>By continuing, you agree to the Belva TOS</a>

                        <div className="flex space-x-2">
                            <button onClick={prevStep} className={buttSty + " hover:w-2/3 w-1/2"}>Back</button>
                            <button onClick={nextStep} className={buttSty + " hover:w-2/3 w-1/2"}>Next</button>

                        </div>

                    </div>
                );
            case 2:
                return (
                    <div className="flex flex-col">
                        <input
                            placeholder="Phone Number"
                            name="phoneNumber"
                            onChange={handleChange}
                            type="phone"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            value={form.phoneNumber}
                            className={inpSty + " border-b"}
                            pattern="\d{10}"
                            required
                            maxLength={10}
                        />

                        <span className="underline text-xs text-center text-gray-800 mt-8 -mb-4 z-[9999]" onClick={() => setStep(5)}>Don't have a US phone number? Use email instead</span>
                        <div className="flex space-x-2">
                            <button onClick={async () => { await sendSMSVerification(); nextStep() }} className={buttSty + " hover:w-2/3 w-1/2"}>Verify</button>
                            {/* <button onClick={prevStep} className={buttSty + " hover:w-2/3 w-1/2"}>Back</button> */}
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="flex flex-col">
                        <input
                            className={inpSty + " border-b"}
                            type="text"
                            name="code"
                            value={form.code}
                            onChange={handleChange}
                            placeholder="Code Input"
                            required
                        />
                        {/* <span className="underline text-xs text-center mt-8 -mb-4" onClick={() => setStep(4)}>Use email instead</span> */}
                        <div className="flex space-x-2">
                            <button onClick={() => { verifySMS().then(() => { nextStep() }) }} className={buttSty + " hover:w-2/3 w-1/2"}>Verify</button>
                            <button onClick={prevStep} className={buttSty + " hover:w-2/3 w-1/2"}>Back</button>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="flex flex-col">
                        <input
                            className={inpSty + " border-b"}
                            type="password"
                            name="password"
                            value={form.password}
                            onChange={handleChange}
                            placeholder="Password"
                            required
                        />
                        {/* <span className="underline text-xs text-center mt-8 -mb-4" onClick={() => setStep(4)}>Use email instead</span> */}
                        <div className="flex space-x-2">
                            <button onClick={handleSubmit} className={buttSty + " hover:w-2/3 w-1/2"}>Submit</button>
                            {/* <button onClick={prevStep} className={buttSty + " hover:w-2/3 w-1/2"}>Back</button> */}
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div className="flex flex-col">
                        <input
                            className={inpSty + " border-b"}
                            type="email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            placeholder="E mail"
                            required
                        />
                        <input
                            className={inpSty + " border-b mt-4"}
                            type="password"
                            name="password"
                            value={form.password}
                            onChange={handleChange}
                            placeholder="Password"
                            required
                        />
                        <input
                            className={` mt-4  focus:outline-none  ${!passwordsMatch ? " border border-red-400 border  focus:outline:none transition-all duration-500 rounded-none py-2 px-3 text-gray-800 bg-none" : " border border-gray-300 text-black py-2 px-3"} `}
                            type="password"
                            name="confirmPassword"
                            value={form.confirmPassword}
                            onChange={(e) => {
                                if (e.target.value !== form.password) {
                                    setPasswordsMatch(false)
                                    console.log("Major mismatch!")
                                } else {
                                    console.log("match")
                                    setPasswordsMatch(true)
                                }
                            }}
                            placeholder="Confirm Password"
                            required
                        />
                        {/* <span className="underline text-xs text-center mt-8 -mb-4" onClick={() => setStep(4)}>Use email instead</span> */}
                        <div className="flex space-x-2">
                            <button onClick={handleSubmit} className={buttSty + " hover:w-2/3 w-1/2 flex"}>
                                {!signUpLoading ? <span className="mx-auto my-auto">Sign Up</span> : <span><AiOutlineLoading className="animate-spin mx-auto my-auto" /></span>}

                            </button>
                            {/* <button onClick={prevStep} className={buttSty + " hover:w-2/3 w-1/2"}>Back</button> */}
                        </div>
                    </div>
                );

            // Other steps...
            default:
                return null;
        }
    }
    return (
        <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-neutral-100 text-white">

            {!isSignedUp ? (
                <div className="flex flex-col md:w-1/2 w-4/5 bg-white p-8 space-y-4 items-center shadow-xl  max-w-md mx-auto">
                    {!actionInProgress ? (<><h2 className="text-2xl absolute top-24 border border-gray-800 p-4 text-gray-800">Join Belva.</h2>
                        <form className="w-full max-w-md" onSubmit={(e) => { setActionInProgress(true); e?.preventDefault(); setActionInProgress(false) }}>
                            {renderStep()}
                        </form>
                    </>) : (<div className="w-full h-full flex flex-col">
                        <AiOutlineLoading className="animate-spin mx-auto my-auto text-5xl text-gray-800" />
                    </div>)}
                </div>
            ) : (
                <>
                    {form.phoneNumber === '' || !welcomeCall ?
                        (<div className="w-full h-screen flex flex-col py-12 text-gray-900">
                            <div className="mx-auto font-mono text-center text-3xl">Add Credits</div>
                            <div className="mx-auto text-center text-xs  w-1/2 font-mono mt-4">
                                <p>
                                    To start making phone calls, you need to add credits. A $5 charge will give you 100 credits, which is approximately equivalent to 5 hours of calling at a rate of $0.05 per minute. <br></br> <br></br> <span className="font-semibold underline">We've given you 10 free credits as a welcome gift, if you want to skip this step.</span>
                                </p>
                            </div>
                            <div className="w-3/5 mx-auto mt-8 bg-white text-gray-900 shadow-2xl shadow-gray-900 rounded-md border-[1.5px] border-gray-300 h-full text-sm z-50">
                                <div className="px-6 mx-auto pt-6 justify-between flex text-3xl font-sans text-gray-800"><span>Total</span> <span>$5</span></div>
                                <Payment />
                            </div>
                            <button className="underline mt-2 text-lg font-semibold" onClick={() => { window.location.href = "/lab" }}>I'll do this later</button>
                        </div>) : (<div>

                            <div className="text-slate-900">{callInProgress ? 'Call sent.' : 'Welcome to Belva. Would you like our AI to give you a welcome call?'}</div>
                            {!callInProgress && <div className="space-x-4 flex">
                                <button className={buttSty} onClick={async () => { await issueWelcomeCall(); }}>Yes please call me.</button>

                                <button className={buttSty} onClick={() => { setWelcomeCall(false) }}>No, not right now</button>

                            </div>}
                            {callInProgress && <button className={buttSty} onClick={() => { setWelcomeCall(false) }}>Done.</button>
                            }


                        </div>)}

                </>
            )}
            {!isSignedUp && <div className="absolute bottom-4 w-full text-center flex"><span className="mx-auto text-slate-600 w-1/3 text-sm">We are still early in our journey. Having issues? Email us at hello@belva.ai or find us on twitter @BelvaInc</span></div>}

        </div>
    );
}

export default Signup;
