import { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckOutForm';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

function Payment(props) {
    const stripePromise = loadStripe('pk_live_51MQz8xBfUBvElDy9RpzeP5fDdyTYYDmdsNP4A82WSVLx1hFEkrs2vxYMR3ME82JInZ7LIDdIpYr9gB5MoOandOMq004RLifOps');

    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        getClientSecret()
    }, [])
    const getClientSecret = async () => {
        const token = localStorage.getItem('token');
        const billingDetails = {
            email: "isaiah@",
        };
        const headers = {
            'Authorization': `${token}`,
        };
        try {
            const { data: clientSecret } = await axios.post('/api/pay/payment', { email: billingDetails.email, card: '' }, { headers });
            console.log(clientSecret)
            setClientSecret(clientSecret?.clientSecret)

        } catch (error) {
            console.log(error)
        }
        console.log(clientSecret)
    }
    return (
        <>

            {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret, }}>
                    <CheckoutForm />
                </Elements>
            )}
        </>
    );
}

export default Payment;