import { useState, useEffect } from 'react';

const useTypingEffect = (text, typingSpeed) => {
    const [typedText, setTypedText] = useState("");

    useEffect(() => {
        let index = 0;
        const timer = setInterval(() => {
            if (index < text.length - 1) {
                setTypedText((prevTypedText) => prevTypedText + text.charAt(index));
                index = index + 1;
            } else {
                clearInterval(timer);
            }
        }, typingSpeed);

        return () => clearInterval(timer);  // Cleanup on unmount
    }, [text, typingSpeed]);

    return typedText;
};
export default useTypingEffect