import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { FiClock, FiCreditCard, FiEye, FiEyeOff } from "react-icons/fi";
import { BiEnvelopeOpen, BiEnvelope, BiBrain } from "react-icons/bi";
import { AiOutlineLoading } from 'react-icons/ai';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { BsArrowUpRightSquare } from "react-icons/bs"
import respell_logo from "../assets/respell_logo.png"
import InputForm from "../components/InputForm";
import { motion } from "framer-motion"
function Dashboard() {
    const [user, setUser] = useState(null);
    const [calls, setCalls] = useState([]);
    const [credits, setCredits] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [showAPIKeys, setShowAPIKeys] = useState(false);
    const [apiKeys, setApiKeys] = useState([]);
    const [showFullAPIKeys, setShowFullAPIKeys] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false)
    const [transcript, setTranscript] = useState(null)
    const [chartData, setChartData] = useState(null)
    const [page, setPage] = useState('dashboard');
    const [callScripts, setCallScripts] = useState(null)
    const endOfMessagesRef = useRef(null); // Reference to the last message

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [callScripts]);

    const codeSnippet = `{
    "phone_number": "1113334444", 
    "objective": "You are calling to do [x] for [y]", 
    "params": {
        "calling_for":"John" 
    },
    "v":"0"
}`;
    const handleViewAPIKeys = async () => {
        try {
            const token = localStorage.getItem("token");
            const headers = {
                'Authorization': `${token}`,
            };

            const { data } = await axios.get('/api/user/getApiKeys', { headers });
            setApiKeys(data);
            setShowAPIKeys(true);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = localStorage.getItem("token");
                const headers = {
                    'Authorization': `${token}`,
                };

                const { data } = await axios.get('/api/user/getUser', { headers });
                setUser(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                // localStorage.clear()
                setLoading(false);
                window.location.href = "/login"
            }
        };

        const fetchCalls = async () => {
            try {
                const token = localStorage.getItem("token");
                const headers = {
                    'Authorization': `${token}`,
                };

                const { data } = await axios.get('/api/user/getCalls', { headers });
                setCalls(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        const fetchCredits = async () => {
            try {
                const token = localStorage.getItem("token");
                const headers = {
                    'Authorization': `${token}`,
                };

                const { data } = await axios.get('/api/user/getCredits', { headers });
                setCredits(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };


        fetchUser();
        fetchCalls();
        fetchCredits();
        handleViewAPIKeys()
    }, []);


    const getChartData = () => {
        // Create an object to store the counts
        const counts = {};

        // Get today's date and the date two days ago
        const today = new Date();
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(today.getDate() - 2);

        // Make sure counts includes all dates from two days ago to today
        for (let day = twoDaysAgo; day <= today; day.setDate(day.getDate() + 1)) {
            const date = day.toLocaleDateString();
            counts[date] = counts[date] || 0;
        }

        calls.forEach((item) => {
            // Get just the date (not time) of created_at
            const date = new Date(item.created_at).toLocaleDateString();

            // If this date is not yet in the counts object, add it with a count of 1
            // If it is, increment the count
            counts[date] = (counts[date] || 0) + 1;
        });
        setChartData(Object.entries(counts).map(([date, count]) => ({ date, count })))
    };
    useEffect(() => {
        if (calls && calls[0]) {
            getChartData();
        }
    }, [calls])

    const handleGetTranscription = async (callId, apiKey) => {
        try {
            const headers = {
                'x-api-key': `${apiKey}`,
            };
            const { data } = await axios.post('https://api.callbelva.com/transcriptions', { call_id: callId }, { headers });
            setTranscript(data?.transcripts)
        } catch (error) {
            console.error(error);
        }
    };


    const handleToggleFullAPIKeys = () => {
        setShowFullAPIKeys((prevShowFullAPIKeys) => !prevShowFullAPIKeys);
    }

    const buttSty = "w-full text-left hover:bg-gray-700 hover:text-gray-200 font-semibold text-gray-800 transition-all py-2 px-2  "
    return (
        <div className="flex min-h-screen bg-white text-gray-900 w-full overflow-x-none">
            <div className="w-64 px-2 pt-6 flex flex-col space-y-1 text-left bg-gray-200 relative border-r border-gray-300">
                <h2 className="text-2xl font-semibold flex space-x-2   mb-4"><span className="my-auto">Belva</span> <span className="text-xs text-red-400 font-light border border-red-400 my-auto mx-2 px-1 ">Beta</span></h2>
                <button className={buttSty} onClick={() => setPage('dashboard')}>Dashboard</button>
                <button className={buttSty} onClick={() => setPage('recent-calls')}>Recent Calls</button>
                <button className={buttSty} onClick={() => setPage('api-keys')}>API Keys</button>
                <button className={buttSty} onClick={() => setPage('call')}>Make a call</button>
                <button className={buttSty + " flex justify-between"} onClick={() => { setPage('api-keys'); window.open("https://belva.gitbook.io") }} >Docs <BsArrowUpRightSquare className="my-auto" /></button>
                <button className={buttSty + " flex justify-between"} onClick={() => { setPage('api-keys'); window.open("https://respell.ai") }} >Use on Respell <img src={respell_logo} className="my-auto w-6 h-6 rounded-full" /></button>
                <div className=" absolute bottom-0 border-t border-gray-200 flex bg-slate-300 w-full px-4 py-4 left-0">
                    <div className="flex">


                        <div className=" bg-blue-500 rounded-full w-8 h-8 flex my-auto">
                            <div className="mx-auto my-auto "> {user?.first_name[0].toUpperCase()}{user?.last_name[0].toUpperCase()}</div>
                        </div>
                        <div className="flex flex-col">
                            <div className="mx-2 text-sm">
                                {user?.first_name.charAt(0).toUpperCase() + user?.first_name.slice(1)} {" "}
                                {user?.last_name.charAt(0).toUpperCase() + user?.last_name.slice(1)}
                            </div>
                            <div className="mx-2 text-xs font-light"> Belva.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="md:w-full w-full mx-auto animate-fade-in">

                {page === 'dashboard' && (

                    <div className="flex flex-col items-center justify-center h-full animate-slide-up">
                        <div className="text-4xl  absolute top-4 font-light text-gray-500  w-full text-center">BELVA</div>
                        <div className="w-1/2 bg-white rounded-none shadow-xl px-6 py-8 border border-gray-200">

                            <h2 className="text-3xl font-semibold text-gray-800 mb-4 animate-char-in">Credits Remaining</h2>

                            <div className="flex justify-between items-center">

                                <motion.span
                                    className="text-5xl font-bold text-gray-900"
                                    initial={{ y: -20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 0.8 }}
                                >
                                    {credits}
                                </motion.span>

                                <motion.div
                                    className="text-gray-600"
                                    initial={{ x: 20, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.8 }}
                                >
                                    <span className="font-bold">{Math.floor(credits / 60)}</span> hour(s)
                                    <span className="font-bold"> {(credits % 60).toFixed(2)}</span> minutes
                                </motion.div>

                            </div>

                            <div


                            >
                                <button onClick={() => { window.open("/pay") }} className="mt-6 text-neutral-800 border border-neutral-500 py-2 px-6 rounded-none hover:bg-neutral-500 transition"> Add Credits
                                </button>
                            </div>

                        </div>

                        <motion.div
                            className="bg-white shadow-xl rounded-none p-6 mt-8 border border-gray-200"
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.2, duration: 0.7 }}
                        >

                            <div className="flex justify-between items-center mb-4">
                                <span className="text-sm font-mono text-red-400 border border-red-400 px-2 py-1">POST</span>
                                <span className="text-sm font-mono text-gray-400">
                                    https://api.callbelva.com
                                </span>
                            </div>

                            <SyntaxHighlighter language="json" style={monokaiSublime}>
                                {codeSnippet.toString()}
                            </SyntaxHighlighter>

                        </motion.div>

                    </div>



                )}
                {page === 'recent-calls' && (
                    <div className="w-full flex h-screen relative">

                        <div className="w-3/4 mx-auto rounded-md shadow-lg p-6 overflow-y-scroll relative">

                            {chartData && <>    <BarChart
                                className="mx-auto cursor-pointer"
                                width={400}
                                height={300}
                                data={chartData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                barSize={15}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="count" fill="#ff0000" />
                            </BarChart>
                            </>}

                            <h2 className="text-2xl font-semibold text-gray-800  mb-4">Recent Calls</h2>
                            {calls.length === 0 ? (
                                <p className="text-gray-400">No recent calls found.</p>
                            ) : (
                                <div className="overflow-y-auto h-96 border-t border-gray-200">

                                    {calls.map((call) => (
                                        <div
                                            key={call.id}
                                            className="flex flex-row text-sm border border-white hover:text-gray-900 transition-all duration-100  justify-between items-center rounded-none my-2 hover:bg-gray-50 px-2 py-1 border-b border-gray-200 cursor-pointer"
                                            onClick={() => { handleGetTranscription(call?.call_id, call?.api_key).then(() => { setShowTranscript(true) }) }}
                                        >
                                            <div className="flex items-center ">

                                                <div className="ml-2">
                                                    <p className=" font-medium">{call.call_to}</p>

                                                </div>

                                            </div>
                                            <div>
                                                <p className=" w-2/3 mx-auto text-sm">{call.parameters.objective}</p>

                                            </div>
                                            <p className="text-gray-400 text-xs">
                                                {new Date(call.created_at).toLocaleString()}
                                            </p>

                                        </div>
                                    ))}
                                </div>
                            )}


                        </div>
                        {showTranscript && (
                            <div className="absolute top-0 left-0 h-screen w-full bg-black bg-opacity-50 flex flex-col">
                                <div className="flex flex-col bg-white w-1/2 my-auto mx-auto px-6 py-4 overflow-y-scroll flex h-2/3">

                                    <div className="flex flex-col overflow-y-scroll my-auto mx-auto ">
                                        {transcript?.map((key, idx) => {
                                            return (
                                                <>
                                                    <div className="rounded-md p-2 my-1 font-mono text-gray-500 flex items-center">
                                                        <span className={(key?.said_by === "human" ? "text-purple-800" : "text-green-500") + " pr-2 flex h-full"}>
                                                            <div className="flex">{key?.said_by === "AI" && <BiBrain className="  align-top text-xl mx-1" />}</div>

                                                            <div className="flex align-top">
                                                                <div className="align-top">{key?.said_by}:</div>
                                                            </div>


                                                        </span>
                                                        <span>{key?.text}</span>
                                                    </div>
                                                    <span className="w-1/4 mx-auto h-[0.5px] bg-gray-200"></span>
                                                </>
                                            )
                                        })}
                                    </div>
                                    <button
                                        className=" mt-8 px-4 py-2 w-1/2 mx-auto bg-white  rounded-none border border-gray-200 hover:bg-gray-300 focus:outline-none"
                                        onClick={() => setShowTranscript(false)}
                                    >
                                        Close
                                    </button>
                                </div>




                            </div>
                        )}
                    </div>
                )}
                {page === 'api-keys' && (

                    <div className="w-full h-full flex flex-col p-5 ">
                        <h3 className="text-xl w-full text-left font-semibold mb-4  mx-auto mb-4">API Keys</h3>
                        {apiKeys.length === 0 ? (
                            <p className="text-gray-400">No API keys found.</p>
                        ) : (
                            <ul className="space-y-4">
                                {apiKeys.map((apiKey) => (
                                    <li key={apiKey.id} className="flex items-center border-b-2 border-gray-200">
                                        <p className=" flex-grow">
                                            {showFullAPIKeys
                                                ? apiKey.apiKey
                                                : `${apiKey.apiKey.slice(0, 4)}${"*".repeat(apiKey.apiKey.length - 4)}`}
                                        </p>
                                        {showFullAPIKeys && (
                                            <FiEyeOff
                                                className="cursor-pointer ml-2 "
                                                size={16}
                                                onClick={handleToggleFullAPIKeys}
                                            />
                                        )}
                                        {!showFullAPIKeys && (
                                            <FiEye
                                                className="cursor-pointer ml-2 "
                                                size={16}
                                                onClick={handleToggleFullAPIKeys}
                                            />
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>

                )}
                {page === 'call' && (
                    <div className="w-full flex h-full relative">
                        <div className="my-auto flex w-full h-full">

                            <InputForm transcript={callScripts} setTranscript={setCallScripts} api_key={apiKeys[0]?.apiKey} /></div>
                        <div className="w-full flex flex-col overflow-y-scroll h-full space-y-2">
                            <div className="flex flex-col overflow-y-scroll my-auto h-5/6 rounded-none bg-gray-100 w-11/12 mx-auto shadow-2xl">
                                {callScripts?.map((key, idx) => {
                                    return (
                                        <div className="rounded-md p-2 my-1 font-mono text-gray-500">
                                            <span className={key?.said_by === "human" ? "text-purple-500" : " text-green-400" + " pr-2"}>{key?.said_by}</span>: {key?.text}
                                        </div>
                                    )
                                })}
                                <div ref={endOfMessagesRef} />
                            </div>
                        </div>

                    </div>
                )}

            </div>
        </div >
    );
}

export default Dashboard;
